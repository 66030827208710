import React, {
  FunctionComponent,
  PropsWithChildren,
  Fragment,
  useEffect,
} from 'react';
import { useApolloClient } from '@apollo/react-hooks';
export type Props = PropsWithChildren<{
  registerResolver: (client: any) => void;
}>;

export const ApolloConsumer: FunctionComponent<Props> = ({
  registerResolver,
  children,
}) => {
  const client = useApolloClient();
  useEffect(() => {
    registerResolver(client);
  }, [client]);

  return <Fragment>{children}</Fragment>;
};

export default ApolloConsumer;
