// For Nodejs
export const dev: boolean = process.env.NODE_ENV !== 'production';
export const isProd: boolean = process.env.NODE_ENV === 'production';
export const isBrowser = typeof window !== undefined;
export const isServer = typeof window === undefined;
export const port = parseInt(process.env.PORT || '3000', 10);
export const isSupportSessionStorage = typeof sessionStorage !== undefined;

// For Nodejs & Javascript in browser
const { HOST_URL } = process.env;
export const HOST = HOST_URL || window.location.origin;
export const chunk = (list: Array<any>, size: number): Array<any> =>
  list.reduce(
    (acc, _, i) => (i % size ? acc : [...acc, list.slice(i, i + size)]),
    []
  );

export const formatCurrency = (
  number: number,
  fixed: number = 0,
  decimal: string = '.'
): string => {
  return number
    .toFixed(fixed)
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${decimal}`);
};

export const formatCurrencyVN = (
  number: number,
  fixed: number = 0,
  decimal: string = '.'
) => `${formatCurrency(number, fixed, decimal)} đ`;

export const slug = (alias: string) => {
  let str = alias;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    '-'
  );
  str = str.replace(/ + /g, '-');
  str = str.replace(/\s/g, '-');
  str = str.trim();

  return encodeURI(str);
};

export const decodeHTML = (html: string) => {
  if (typeof document === 'object') {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  return html;
};

export const absoluteUrl = (uri: string) => {
  if (!uri) return '';
  return isProd ? `${HOST_URL}${uri}` : uri;
};
