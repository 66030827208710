import React, { FunctionComponent, forwardRef } from 'react';
import { BasicProps } from './Component';
import classNames from 'classnames';

export type TParentComponentProps = BasicProps & {
  defaultClassName?: string;
};

export const ParentComponent: FunctionComponent<TParentComponentProps> = forwardRef(
  (props, ref) => {
    const {
      defaultClassName,
      className,
      as: Component = 'div',
      ...otherProps
    } = props;
    const cx = classNames({
      [defaultClassName]: !!defaultClassName,
      [className]: !!className,
    });

    return <Component ref={ref} className={cx} {...otherProps} />;
  }
);

export default ParentComponent;
