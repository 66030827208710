import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';
import classNames from 'classnames';

export type TBlockTitleProps = TParentComponentProps & {
  black?: boolean;
  heading?: boolean;
  normal?: boolean;
};
export const BlockTitle: FunctionComponent<TBlockTitleProps> = ({
  className,
  black,
  heading,
  normal,
  ...otherProps
}) => {
  const cx = classNames({
    'block__title--black': black,
    'block__title--heading': heading,
    'block__title--normal': normal,
    [className]: !!className,
  });

  return <ParentComponent className={cx} {...otherProps} />;
};

BlockTitle.defaultProps = {
  defaultClassName: 'block__title',
  black: false,
};

export default BlockTitle;
