import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Image = ({
  image: { src, placeholder = '/assets/default.png', ...rest },
  wrapperStyle = {},
}) => {
  const [error, setError] = React.useState(false);
  const onError = () => {
    setError(true);
  };

  if (error) {
    return (
      <div style={wrapperStyle}>
        <img src={placeholder} {...rest} />
      </div>
    );
  }

  return (
    <div style={wrapperStyle}>
      <LazyLoadImage
        src={src}
        placeholderSrc={placeholder}
        {...rest}
        onError={onError}
      />
    </div>
  );
};

export default Image;
